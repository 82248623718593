'use strict';

import $ from 'jquery';
import { debounce, isBreakpointActive } from './helpers';

window.app = window.app || {};
window.app.variables = window.app.variables || {};

const $document = $(document);
const $window = $(window);
const $html = $('html');
const $body = $('body');

windowResize();
collapseSearch();

$window.on('resize', debounce(windowResize, 250, false));

$window.on('load', jsDone);

setTimeout(jsDone, 4000);

$('.js-notice-close').on('click', function (e) {

    e.preventDefault();

    const $notice = $(this).closest('.notice');

    $notice.slideToggle(250, () => {
        $notice.remove();
    });
});

function windowResize() {

    const isFlyoutActive = isBreakpointActive('flyout');
    const windowWidth = $window.width();
    const windowHeight = $window.height();

    if (!isFlyoutActive) {
        $body.removeClass('flyout-active');
    }

    $.extend(window.app.variables, {
        isFlyoutActive,
        windowWidth,
        windowHeight
    });
}

function collapseSearch() {

    let $form = $('.js-search-form');
    let $formTrigger = $('.js-search-trigger');

    // register clicks and toggle classes
    $formTrigger.on('click', function (e) {

        e.preventDefault();

        const $container = $(this).closest('.js-search-container');
        const $input = $container.find('.js-search-input');

        if ($form.hasClass('active')) {
            $form.removeClass('active');
            $body.removeClass('search-active');
            $(this).find('.icon').addClass('icon--magnify');
            $(this).find('.icon').removeClass('icon--clear');
            $container.removeClass('active');
        } else {
            $input.addClass('focus');
            $form.addClass('active');
            $body.addClass('search-active');
            $(this).find('.icon').addClass('icon--clear');
            $(this).find('.icon').removeClass('icon--magnify');
            $container.addClass('active');

            const $inputId = $form.find('.js-search-focus').attr('id');
            $('#' + $inputId).focus();
        }
    });

    $document.on('click', '.search__close, .overlay', function () {

        $body.removeClass('search-active');
        $form.removeClass('active');
        $('.js-search-container').removeClass('active');
        $formTrigger.find('.icon').addClass('icon--magnify');
        $formTrigger.find('.icon').removeClass('icon--clear');

    });

    $document.keyup( function (e) {

        if (e.keyCode === 27) {
            if ($form.hasClass('active')) {
                $form.removeClass('active');
                $body.removeClass('search-active');
                $('.js-search-container').removeClass('active');
                $formTrigger.find('.icon').addClass('icon--magnify');
                $formTrigger.find('.icon').removeClass('icon--clear');
            }
        }
    });

}

function jsDone() {
    $html.addClass('js-done');
}
