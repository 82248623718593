'use strict';

require('selectize');

import $ from 'jquery';

$('.js-selectize').selectize({
    maxItems: 4,
    plugins: ['remove_button']
});

$('.js-selectize-frontend').selectize({
    maxItems: 1,
    plugins: ['remove_button']
});
