'use strict';

import $ from 'jquery';

import './components/common';
import './components/form';
import './components/flyout';
import './components/lazySizes';
// import './components/subscribe';

import './components/selectize';
import './components/responsiveHero';

//  Icon font generation - do not remove
import '../icons/iconfont.font';
